<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>呼叫记录</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>
    <el-row style="padding: 20px;display: flex; align-items: center;border-bottom: 1px solid #e5e5e5;">
      <el-col :span="6">
        <span>创建时间：</span>
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="2">
        <el-select
          v-model="option1"
          clearable
          placeholder="订单状态"
        >
          <el-option
            v-for="item in option1es"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="7">
        <el-input v-model="QueryContent" placeholder="输入要查询的手机号,姓名,地址" style="margin-right: 3px;"></el-input>
        <el-button type="success" size="mini">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img
              style="width: 16px; height: 16px"
              :src="require('@/assets/iconImg/logo.png')"
            />
            <span>查询</span>
          </div>
        </el-button>
      </el-col>
    </el-row>
    <div class="table">
      <div>
        <el-table
          ref="multipleTable"
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; border: 1px solid #e5e5e5"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center"> </el-table-column>
          <el-table-column
            prop="creatDate"
            sortable
            label="创建日期"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="phone" label="电话手机号" align="center">
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.status }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <i
                class="el-icon-edit"
                style="cursor: pointer"
                @click="handleEdit(scope.$index, scope.row)"
              ></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete"
                style="cursor: pointer"
                @click="handleDelete(scope.$index, scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span v-if="isResult" style=""
            >显示第&nbsp;1&nbsp;至&nbsp;{{
              tableFinshNum
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >
          <span v-if="!isResult" style=""
            >显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
              tableFinshNum
            }}&nbsp;项</span
          >
          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              :total="1"
            ></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      time: "",
      option1:"",
      option1es:[],
      QueryContent: "",

      statusRadio: "全部",

      tableData: [
        {
          id: 1,
          creatDate: "",
          remark: "",
          status: "已确认",
        },
        {
          id: 2,
          creatDate: "",
          remark: "",
          status: "未确认",
        },
        {
          id: 3,
          creatDate: "",
          remark: "",
          status: "已确认",
        },
      ],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
    handleEdit() {},
    handleDelete() {},
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  padding: 20px;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin: 0 5px;
}

/deep/ .cell{
  padding: 0 !important;
}
</style>
